<template>
  <v-main>
    <div
      :class="`${website.panel_drawer ? 'ml-4' :''} p-4`"
    >
      <v-card class="rounded-xl-bk" :elevation="3">
        <v-progress-linear
          :active="website.progress.loading"
          :indeterminate="website.progress.loading"
          :reverse="website.progress.reverse"
          :color="website.progress.color || 'cyan' "
        ></v-progress-linear>
        <v-toolbar >
          <v-btn icon @click="website.setPanelDrawer(!website.panel_drawer)" :elevation="3" v-if="!website.panel_drawer">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ website.title }}
          </v-toolbar-title>
          <!-- 通知 -->
          <panel-notice v-if="false" />

          <v-divider vertical thickness="3" class="ml-2 mr-2"></v-divider>
          <!-- 用户 -->
          <panel-user />
        </v-toolbar>
      </v-card>
    </div>
  </v-main>
</template>

<script setup lang="ts" >



import { ref } from 'vue'
import { useTheme } from 'vuetify'


  const website = useWebsiteStore()
  const theme = useTheme()
  const toggleTheme = () => {
    website.setDark(!website.dark)
    theme.global.name.value = !website.dark ? 'light' : 'dark'
  }
  const items = ref([])

</script>
