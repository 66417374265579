<template>
  <v-navigation-drawer :elevation="3" :class="'my-4 rounded-xl-bk '+(website.panel_drawer ? 'mx-4' : '')" v-model="website.panel_drawer" style="border-style:unset; height: calc(100% - 32px); z-index: 2001;" >
    <!-- 菜单 -->
    <div class="pa-5 pl-4">
      <v-list>
        <div class="d-flex justify-left">
          <a data-fslightbox="logo" href="/logo.png">
            <v-img
              alt=""
              :src="website.user.avatar as string"
              style="height: 32px; width: 32px;"
            ></v-img>
          </a>
          <div class="leading-8 font-medium text-2xl ml-2">{{ website.user.username }}</div>
        </div>
        <v-divider class="my-4"></v-divider>
        <panel-navbar-list :list="navItems">
        </panel-navbar-list>
      </v-list>
    </div>
</v-navigation-drawer>
</template>

<script setup lang="ts">
  import type { PageQuery, Tab } from '~/api/http_types';
  import api from '~/api/_api';
  import type { List } from './navbar/types';


  const website = useWebsiteStore()
  
  const vdm = ref(true)

  const navItems = ref([
    // {
    //   id: 1,
    //   name: '仪表台',
    //   icon: 'mdi-view-dashboard',
    //   path: '/',
    // },
    // {
    //   id: 2,
    //   name: '订单管理',
    //   path: '/order',
    //   children: [
    //     {
    //       id: 21,
    //       name: '所有订单',
    //       path: '/order/list',
    //       icon: 'mdi-cart',
    //     },
    //     {
    //       id: 22,
    //       name: '待支付',
    //       path: '/order/list?unpaid=true',
    //     },
    //     {
    //       id: 23,
    //       name: '待发货',
    //       path: '/order/list?unshipped=true',
    //     },
    //   ],
    // },

  ] as Array<List>)

  const query = ref({
    page: 1,
    page_size: 1000,
    tabs: [
      {
        key: 'Type',
        value: '1',
      }
    ] as Tab[],
  } as PageQuery)

  const init = () => {
    api.route.getMenu().then((res) => {
      console.log(res)
      navItems.value = res.data.data as List[]
    })
  }
  if(process.client){
    init()
  }
  
</script>
